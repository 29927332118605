import * as React from "react"
import Layout from "../components/Layout"
import styled from 'styled-components'
import Seo from '../components/Seo'
import regulamin from "../assets/regulamin_roztocze2024.pdf"

const Title = styled.h1`
  text-align: center;
`


const Regulamin = () => {
  return (
    <Layout>
        <Seo title="Zapisy Ultramaraton Gravelowy"/>
        <Title>REGULAMIN MORE ULTRA RACE ROZTOCZE 2024</Title>
        <a href={regulamin} title="Regulamin Ultra Race Roztocze">
                <h3>REGULAMIN</h3>
        </a>
    </Layout>
  )
}

export default Regulamin
